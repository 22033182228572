<template>
  <div>
    <div>
      <globe v-if="screenSize == 'lg' || screenSize == 'xl'"></globe>
      <div v-else>
        <div class="row justify-content-center my-5 py-3">
          <div class="col-8 col-sm-6 col-md-5 mb-4">
            <img
              :src="require('@/assets/images/leprocess_logo_dark.png')"
              class="w-100"
              alt="Le Process logo dark"
            />
          </div>
          <div class="col-11 col-sm-8 text-center">
            <p class="text-dark font-bold font-size-2 mb-2">
              Contenu de la formation
            </p>
            <p class="badge badge-red px-4 py-2 rounded mb-5">
              Nouveau module pour la rentrée 2021 !
            </p>
            <button
              v-for="(chapter, index) in chapters"
              :key="index"
              class="btn btn-lg font-size-3 font-bold mb-3 w-100"
              :class="'btn-outline-chapter-' + chapter.id"
              @click="$common.showChapterModal(chapter.id)"
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              <span>{{ chapter.name }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row my-5 py-5 justify-content-center align-items-center">
      <div class="col-11 col-sm-9 col-md-5 col-lg-4 mb-4 mb-md-0">
        <p class="text-dark font-bold font-size-2 line-height-sm mb-4">
          Un programme pour les développeurs où on ne code PAS.
        </p>
        <p class="text-light">
          Vous pouvez chercher, il n'existe pas d'équivalent ailleurs.
        </p>
        <p class="text-light">
          135 vidéos pour faire le tour de la question : qu'est-ce que vous
          devez savoir pour devenir un vrai développeur ?
        </p>
        <p class="text-light">
          Coder oui, mais tout le reste, ça se passe comment ?
        </p>
        <p class="text-light">
          Toutes ces choses qu'on aurait aimé savoir dès le départ...
        </p>
      </div>
      <div class="col-11 col-sm-9 col-md-5 col-lg-4 offset-lg-1">
        <img
          :src="require('@/assets/images/home/illus_mindblown.png')"
          class="w-100"
          alt="Mindblow"
        />
      </div>
    </div>

    <div class="row my-5 py-5 justify-content-center align-items-center">
      <div
        class="col-11 col-sm-9 col-md-5 col-lg-4 text-center order-2 order-md-1"
      >
        <img
          :src="require('@/assets/images/home/illus_max.png')"
          class="w-50"
          alt="Maxime Dutres formateur"
        />
        <div class="w-100"></div>
        <img
          :src="require('@/assets/images/home/logo_youtube.png')"
          class="mt-3"
          style="width: 10%"
          alt="Logo YouTube"
        />
      </div>
      <div
        class="
          col-11 col-sm-9 col-md-5 col-lg-4
          offset-lg-1
          order-1 order-md-2
          mb-5 mb-md-0
        "
      >
        <p class="text-dark font-bold font-size-3 line-height-sm mb-4">
          Si je devais passer une semaine avec un développeur débutant ou en
          devenir, c'est ce que je voudrai lui transmettre.
        </p>
        <p class="text-light">
          Je voulais faire un produit unique capable de répondre à toutes les
          interrogations que je retrouve quotidiennement dans les commentaires
          de mes vidéos YouTube.
        </p>
        <p class="text-light">
          Après plusieurs mois de travail, voici le fruit de cette réflexion.
        </p>
        <p class="text-light">
          Tous les conseils des développeurs professionnels réunis en un seul et
          même endroit.
        </p>
      </div>
    </div>

    <div class="row my-5 py-5 justify-content-center">
      <div class="col-11 col-md-9 col-lg-8 col-xl-6 text-center">
        <p class="text-dark font-bold font-size-2 line-height-sm mb-2">
          J'ai voulu créer le complément indispensable à toutes les autres
          formations de développeur.
        </p>
        <p class="text-light mb-4">
          Pour permettre à ceux qui le souhaitent de prendre une longueur
          d'avance et de ne pas se fondre dans la masse des nouveaux
          développeurs.
        </p>
      </div>
      <div class="w-100 my-3"></div>
      <div class="col-11 col-sm-9 col-md-7 col-lg-5 text-center">
        <youtube
          video-id="3vkieaZ6gkQ"
          class="w-100"
          id="video-youtube-container"
          :player-width="videoYouTubeWidth"
          :player-height="videoYouTubeHeight"
          @ready="setYouTubePlayerSize"
        ></youtube>
      </div>
    </div>

    <div class="row my-5 py-5 justify-content-center" id="informations">
      <div class="col-11 col-md-9 col-lg-7 px-2 px-md-3">
        <div class="border-dark">
          <div class="row py-3 justify-content-center">
            <div class="col-11 col-md-9 col-lg-7 my-4">
              <p class="w-100 text-center text-pink font-bold font-size-3 mb-2">
                Inclus dans le process :
              </p>
              <p class="text-light line-height-lg">
                <i class="font-size-5 text-success fas fa-check-circle"></i> 135
                vidéos à la demande, disponibles n'importe quand, de n'importe
                où.
              </p>
              <p class="text-light line-height-lg">
                <i class="font-size-5 text-success fas fa-check-circle"></i> 135
                cheat sheets (une par vidéo) pour emporter avec vous les points
                essentiels, que vous débloquez au fur et à mesure des vidéos.
              </p>
              <p class="text-light line-height-lg">
                <i class="font-size-5 text-success fas fa-check-circle"></i> Un
                accès au Discord privé LE PROCESS où vos questions sont traitées
                en priorité.
              </p>
              <p class="text-light line-height-lg">
                <i class="font-size-5 text-success fas fa-check-circle"></i>
                Tableau de bord pour suivre votre avancement.
              </p>
              <p class="text-light mb-2">
                <i class="font-size-5 text-success fas fa-check-circle"></i>
                Le guide ultime pour un entretien de développeur (bonus rentrée
                2021).
              </p>
            </div>
            <div class="col-11 col-md-9 col-lg-7 my-4">
              <p class="w-100 text-center text-dark font-bold font-size-3 mb-2">
                Attention !
              </p>
              <p class="text-light line-height-lg">
                - Le Process ne vous apprendra pas à coder et ne fera pas de
                vous un développeur.
              </p>
              <p class="text-light line-height-lg">
                - Le Process est à destination des développeurs débutants ou en
                devenir et peut correspondre à toute personne n'ayant aucune
                expérience dans le milieu, comme tout développeur ayant moins de
                5 ans d'expérience professionnelle.
              </p>
              <p class="text-light line-height-lg">
                - Le Process est un programme comprenant la patte des Frères
                Codeurs et comporte donc un peu d'humour tout en restant
                extrêmement sérieux sur le fond.
              </p>
            </div>
            <div class="col-11 col-md-9 col-lg-7 my-4 text-center">
              <p class="text-pink font-bold font-size-2 line-height-sm">
                C'est 6x49€
              </p>
              <p class="text-pink font-bold font-size-4 line-height-sm">
                étalés sur 6 mois
              </p>
              <p
                class="text-pink font-bold font-size-3 line-height-sm"
                :class="{
                  'text-pink-line-through': remainingSpecialOffers > 0,
                  'font-size-4': remainingSpecialOffers > 0,
                }"
              >
                ou 289€ en une fois
              </p>
              <p
                v-if="remainingSpecialOffers > 0"
                class="text-red font-bold font-size-3 line-height-sm mb-2"
              >
                ou 199€ en une fois
              </p>
              <p
                v-if="remainingSpecialOffers > 0"
                class="badge badge-red px-4 py-2 rounded"
              >
                Promotion rentrée 2021 : {{ remainingSpecialOffers
                }}{{ remainingSpecialOffers > 1 ? " restants" : " restant" }}
              </p>
            </div>
            <div class="col-11 col-md-9 col-lg-7 my-4">
              <p class="w-100 text-center text-dark font-bold font-size-3 mb-2">
                Pourquoi ce prix ?
              </p>
              <p class="text-light line-height-lg">
                - Le Process est un produit totalement unique sous bien des
                aspects, il est le seul qui offre un aperçu aussi large et
                complet de l'univers des développeurs.
              </p>
              <p class="text-light line-height-lg">
                - Il s'agit du condensé de l'expérience agglomérée de
                développeurs professionnels sur de nombreuses années, cela vaut
                bien plus que 6x49€ sur le papier.
              </p>
              <p class="text-light line-height-lg">
                - Si vous n'êtes pas prêt à investir quelques centaines d'euros
                pour préparer au mieux votre carrière de développeur, Le Process
                n'est pas fait pour vous.
              </p>
              <p class="text-light line-height-lg">
                - Vous ne trouverez rien de semblable ailleurs, la réponse à vos
                questions et tout ce qu'un développeur doit savoir
                impérativement se situent dans Le Process.
              </p>
            </div>
            <div
              class="col-11 col-md-9 col-lg-7 text-center mt-5 mb-3"
              v-if="$authentification.user == null"
            >
              <router-link to="/payment">
                <p
                  class="btn btn-xl btn-outline-pink"
                  @click="$router.push('/payment')"
                >
                  REJOINDRE LE PROCESS
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return this.$common.getPageMeta();
  },
  jsonld() {
    return this.$common.getJsonLd();
  },
  data() {
    return {
      screenSize: null,

      showChevron: true,

      remainingSpecialOffers: 0,

      videoYouTubeWidth: 1,
      videoYouTubeHeight: 1,

      chapters: this.$common.getSummary().chapters,
    };
  },
  methods: {
    getSpecialOfferCount() {
      this.$api.discounts.getRentree2021Count().then((res) => {
        this.remainingSpecialOffers = res.data.count;
      });
    },
    getScreenSize() {
      this.screenSize = this.$common.getScreenSize();
    },
    setYouTubePlayerSize() {
      const ratio = 16 / 9;
      const videoContainer = document.getElementById("video-youtube-container");
      this.videoYouTubeWidth = videoContainer.clientWidth;
      this.videoYouTubeHeight = this.videoYouTubeWidth / ratio;
    },
    scrollToInformations(duration) {
      this.$scrollTo("#informations", duration);
    },
  },
  created: function () {
    this.getScreenSize();
    this.getSpecialOfferCount();
  },
  mounted: function () {
    this.$eventHub.$on("showInformations", () => {
      this.scrollToInformations(500);
    });

    window.addEventListener("resize", this.setYouTubePlayerSize);
    window.addEventListener("resize", this.getScreenSize);
  },
  destroyed() {
    this.$eventHub.$off("showInformations");
    window.removeEventListener("resize", this.setYouTubePlayerSize);
    window.removeEventListener("resize", this.getScreenSize);
  },
  watch: {},
};
</script>
